import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {postJSON} from "common/network";
import { Stack } from "@mui/material";

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid, Input,
    Typography,
} from "@material-ui/core";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        padding: theme.spacing(4),
        marginBottom: theme.spacing(8),
    },
    title: {
        // color: 'red'
    },
    addPackageButton: {
        marginRight: "8px",
        marginTop: "8px",
    },
    packageContainer: {
        backgroundColor: "white",
        marginTop: "28px",
        minHeight: "400px",
    },
    popul8Container: {
        backgroundColor: "white",
        marginTop: "28px",
        minHeight: "120px",
    },
    divider: {
        height: "4px",
        backgroundColor: theme.palette.primary.main,
    },
    expireTime: {
        color: theme.palette.primary.contrastText,
    },
    expireOn: {
        color: theme.palette.primary.contrastText,
        height: "18px",
    },
    editButton: {
        backgroundColor: theme.palette.primary.contrastText,
        color: "#66ee98",
        width: "25px",
        height: "25px",
        borderRadius: "50%",
    },
    expiredDateModalTitle: {
        paddingTop: "12px",
        paddingBottom: "18px",
    },
    expiredDateModalDescription: {
        paddingTop: "50px",
        paddingBottom: "40px",
        fontSize: "18px",
        fontWeight: "normal",
    },
    cancelButton: {
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        padding: "8px 8px",
        maxWidth: "220px",
        width: "100%",
        borderRadius: "8px",
        fontSize: "18px",
        fontWeight: "bold",
    },
    confirmButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: "8px 8px",
        maxWidth: "220px",
        width: "100%",
        borderRadius: "8px",
        fontSize: "18px",
        fontWeight: "bold",
    },
    emailInput: {
        flexGrow: 1,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.05px',
        border: '1px solid black'
    }
}));


const UserBasicRegistrationForm = () => {
    const classes = useStyles();
    const [user, setUser] = useState({
        Email: "",
        SendMessage: true
    });

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            postJSON(process.env.REACT_APP_API_ROOT + `v3/accounts/register`, JSON.stringify(user))
            .then((response) => {
                //if success then alert
                if(response.uuid){
                    setUser({Email:"", SendMessage: true});
                    navigate(`/accounts/${response.uuid}`);
                } else {
                    alert("User creation failed");
                }
                setLoading(false);
            });

        } catch (err) {
            console.log("fetchAccountStatus:", err);
            alert("User creation failed");
            setLoading(false);
        }
        return false;
    }

    const [loading, setLoading] = useState(false);

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Card className={classes.root}>
                    <CardHeader title="Add Account" />
                    <CardContent>
                        {loading ? (<Typography>Loading...</Typography>) : (
                            <Stack spacing={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography>User Details</Typography>
                                    </Grid>
                                    <Grid item xs={12}>

                                        <Typography>Email:</Typography>
                                        <Input
                                            type="text" name="Email"
                                            className={classes.emailInput}
                                            style={{ width: '300px', height: '40px' }}
                                            disableUnderline
                                            variant="outlined"
                                            onChange={(e) => setUser({...user, Email: e.target.value})}
                                        />

                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary">
                                            Submit
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Stack>
                        )}
                    </CardContent>
                </Card>
            </form>
        </div>
    );
}

export default UserBasicRegistrationForm