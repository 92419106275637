import React from "react";
import { RequireAuth } from "components/RequireAuth";
import { Navigate } from "react-router";
import {
    Dashboard as DashboardView,
    UserList as UserListView,
    InvoiceReport as InvoiceReportView,
    ExternalSignIn as SignInView,
    ConfigureAndSpend as ConfigureAndSpendView,
    QueueConfigList as QueueConfigListView,
    DGPList as DGPListView,
    DGPDetails as DGPDetailsView
} from "views";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";
import UserDetails from 'views/UserList/UserDetails';
import UserBasicRegistrationForm from 'views/UserList/UserBasicRegistrationForm';

const routes = () => {
    return [
        { path: '/', element: <Navigate replace to="/dashboard" /> },
        { path: '/dashboard', element: <RequireAuth group="Employees"><MainLayout><DashboardView /></MainLayout></RequireAuth> },
        { path: '/accounts', element: <RequireAuth userGroup="Administrators"><MainLayout><UserListView /></MainLayout></RequireAuth> },
        {
            path: '/accounts/new',
            element: <RequireAuth userGroup="Administrators"><MainLayout><UserBasicRegistrationForm /></MainLayout></RequireAuth>
        },
        {
            path: '/accounts/:uuid',
            element: <RequireAuth userGroup="Administrators"><MainLayout><UserDetails /></MainLayout></RequireAuth>
        },
        {
            path: '/dgps',
            element: <RequireAuth userGroup="Administrators"><MainLayout><DGPListView /></MainLayout></RequireAuth>
        },
        {
            path: '/dgps/:uuid',
            element: <RequireAuth userGroup="Administrators"><MainLayout><DGPDetailsView /></MainLayout></RequireAuth>
        },
        { path: '/dgps/queues', element: <RequireAuth userGroup="Administrators"><MainLayout><QueueConfigListView /></MainLayout></RequireAuth> },
        { path: '/reports/orders', element: <RequireAuth userGroup="Employees"><MainLayout><InvoiceReportView /></MainLayout></RequireAuth> },
        { path: '/conf-and-spend', element: <RequireAuth userGroup="Administrators"><MainLayout><ConfigureAndSpendView /></MainLayout></RequireAuth> },
        {
            path: "/profile/login",
            element: <MinimalLayout><SignInView /></MinimalLayout>
        },
        { path: '*', element: <Navigate replace to="/" /> },
    ]
};

export default routes;